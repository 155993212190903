import Container from '@components/ui/Container'
import cn from '@utils/helpers/cn'
import { useContext, useMemo, memo } from 'react'
import ConfigContext from '@utils/context/config'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'
import { useRouter } from 'next/router'

// Memoized StoryblokBlock component to prevent unnecessary re-renders
const MemoizedStoryblokBlock = memo(({ blok, index }) => (
  <StoryblokComponent index={index} blok={blok} key={blok._uid} />
))

MemoizedStoryblokBlock.displayName = 'MemoizedStoryblokBlock'

// Filter function extracted to reduce calculations on each render
const filterVisibleBlocks = (blocks = []) =>
  blocks.filter(
    (b) =>
      (b.hasOwnProperty('showBlock') && b.showBlock) ||
      !b.hasOwnProperty('showBlock'),
  )

const Footer = () => {
  const router = useRouter()
  const config = useContext(ConfigContext)
  const componentState = config?.footer?.component || {}
  const infoState = config?.footer?.info || {}

  // Memoize this calculation to avoid checking on every render
  const isCartPage = useMemo(
    () => router.pathname?.startsWith('/[locales]/cart'),
    [router.pathname],
  )

  // Memoize filtered blocks to prevent recalculation on every render
  const visibleComponentBlocks = useMemo(
    () => filterVisibleBlocks(componentState?.blocks),
    [componentState?.blocks],
  )

  const visibleInfoBlocks = useMemo(
    () => filterVisibleBlocks(infoState?.blocks),
    [infoState?.blocks],
  )

  // Pre-compute component class to avoid string concatenation during render
  const componentClassName = useMemo(
    () => cn(componentState?.class || '', 'flex flex-col'),
    [componentState?.class],
  )

  const infoClassName = useMemo(
    () => cn(infoState?.class || ''),
    [infoState?.class],
  )

  return (
    <footer className={cn('bg-[#282d34] print:hidden')}>
      {!isCartPage && (
        <Container className="py-5 max-lg:py-12">
          <div className={componentClassName}>
            {visibleComponentBlocks?.map((blok, index) => (
              <MemoizedStoryblokBlock
                index={index}
                blok={blok}
                key={blok._uid}
              />
            ))}
          </div>
        </Container>
      )}
      <div className="mb-20 w-full border-t border-[#414750] lg:mb-0">
        <Container className="py-5 max-lg:py-12">
          <div className={infoClassName}>
            {visibleInfoBlocks?.map((blok, index) => (
              <MemoizedStoryblokBlock
                index={index}
                blok={blok}
                key={blok._uid}
              />
            ))}
          </div>
        </Container>
      </div>
    </footer>
  )
}

// Use memo to prevent unnecessary re-renders of the entire footer
export default memo(Footer)
